import { defineStore } from 'pinia'

interface AppStateType {
  isDark: boolean
  theme: string
}
const colorMode = useColorMode()

export const useAppStore = defineStore({
  id: 'appStore',
  state: () => <AppStateType>({
    isDark: false,
    theme: colorMode.preference
  }),
  actions: {
    initializeDarkMode() {
      localStorage.setItem("is-dark-mode", "true")
      this.isDark = localStorage.getItem("is-dark-mode") === 'true';
    },
    toggleTheme(value: boolean) {
      this.isDark = value
      this.theme = colorMode.preference
      if (this.isDark) {
        colorMode.preference = "dark"
        localStorage.setItem("is-dark-mode", "true");
      } else {
        colorMode.preference = 'light'
      }
      localStorage.setItem("nuxt-color-mode", colorMode.preference)
    },
    resetIsDark() {
      this.isDark = false
      this.theme = 'light'
    }
  }
})