<template>
  <main class="relative mx-auto">
    <transition name="fade">
      <div v-show="isShowNewSubscriber"
        class="subscribe overflow-hidden bg-black dark:bg-[#3e3e3e] text-white relative">
        <div class="py-2 w-full md:max-w-[calc(100vw-130px)] mx-auto">
          <NewSubscriber heading="Stay Updated with the Latest Insights. Join us"
            subheading="We'd love to send some extremely insightful content right to your inbox. Just share your email address with us, and we'll take of the rest!"
            btn-label="Subscribe" :single-row="true" />
        </div>
        <span class="block absolute top-[0.7rem] md:right-[2rem] right-[1rem] cursor-pointer"
          @click="closeNewSubscriberAlert()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </span>
        <!-- <hr /> -->
      </div>
    </transition>
    <div class="relative mx-auto min-h-screen">
      <Navbar />
      <div class="pt-[70px]">
        <NuxtPage />
      </div>
    </div>
    <div class="mt-12 py-12 border-t border-t-gray-200 dark:border-t-gray-800 bg-black text-white">
      <NewSubscriber heading="Stay Updated with the Latest Insights"
        subheading="Join our community and get the freshest discussions straight to your inbox. Just drop your email below, and we’ll handle the rest. No spam, just quality content — we promise!"
        btn-label="Subscribe" :single-row="false" />
    </div>
    <Footer />
    <div v-show="scrollPosition > 100"
      class="w-[60px] h-[60px] p-2 rounded-full border-white border-[2px] bg-black fixed bottom-[5vh] right-[5vw] flex items-center justify-center cursor-pointer"
      @click="backToTop">
      <span class="text-white text-[11px] leading-[20px]">TOP</span>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24">
        <path fill="#FFFFFF"
          d="M17.71,11.29l-5-5a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-5,5a1,1,0,0,0,1.42,1.42L11,9.41V17a1,1,0,0,0,2,0V9.41l3.29,3.3a1,1,0,0,0,1.42,0A1,1,0,0,0,17.71,11.29Z" />
      </svg>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useNewSubscribersStore } from '../stores/newSubscribers' // Adjust this path to your actual store path

// State for scroll position
const scrollPosition = ref<number>(0)

// Using route and router in Composition API
const route = useRoute()
const store = useNewSubscribersStore()
const appStore = useAppStore()

// Computed property to access store state for showing new subscriber alert
const isShowNewSubscriber = computed(() => store.showNewSubscribers)

// Watch for route changes
watch(route, (to) => {
  store.setShowSubscribers(to.path === '/')
})

// Set the initial state and add scroll listener on mount
onMounted(() => {
  appStore.initializeDarkMode()
  // Set initial state for new subscriber alert based on the route
  store.setShowSubscribers(route.path === '/')

  const handleScroll = () => {
    scrollPosition.value = window.scrollY
  }
  window.addEventListener('scroll', handleScroll)

  // Clean up on unmounted
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })
})

// Methods
const backToTop = () => {
  if ((scrollPosition.value ?? 0) > 100) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const closeNewSubscriberAlert = () => {
  try {
    store.setShowSubscribers(false)
  } catch (error) {
    console.error(`Error committing store action: ${error}`)
  }
}

</script>

<style>
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
} */
</style>
