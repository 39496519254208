<template>
  <div class="mx-auto top-0 w-full z-[500] navTransition" :class="[
    !isNavOpen ? 'dark:bg-darkBlack bg-[#ffffff] backdrop-blur-[3px]' : 'bg-[transparent]',
    isShowNewSubscriber === false ? 'fixed' : 'absolute',
  ]">
    <div class="flex px-5 py-6">
      <header class="flex w-full justify-between items-center md:max-w-[1180px] md:px-[20px] mx-auto">
        <div class="flex justify-between items-center z-[500]">
          <a href="https://www.kinfolk.vc" :class="isNavOpen ? 'invisible' : ''"
            class="w-[40px] h-[40px] flex items-center md:mr-2 mr-1">
            <img v-if="colorMode.value === 'dark'" src="../assets/svg/kinfolkwhite.svg" alt=""
              style="width: 30px; height: 30px">
            <img v-if="colorMode.value === 'light'" src="../assets/svg/kinfolk.svg" alt=""
              style="width: 30px; height: 30px">
          </a>
        </div>
        <NuxtLink to="/">
          <div class="flex items-center z-[500]">
            <h1 class="md:text-[24px] text-[18px] leading-0 text-center font-bold">
              CHRONICLES
            </h1>
          </div>
        </NuxtLink>

        <div v-if="isNavOpen" class="flex items-center z-[1000]">
          <Menu as="div" class="relative inline-block text-left mr-3">
            <div>
              <MenuButton
                class="inline-flex w-full justify-center rounded-md bg-black/20 p-2 text-sm font-medium text-white hover:bg-black/30 border dark:hover:border-gray-700 border-transparent transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <span class="w-5 h-5">
                  <svg v-if="colorMode.value === 'system'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>
                  <svg v-if="colorMode.value === 'light'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                  </svg>
                  <svg v-if="colorMode.value === 'dark'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                  </svg>
                </span>
              </MenuButton>
            </div>

            <transition enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0">
              <MenuItems
                class="absolute right-0 mt-2 w-36 origin-top-right divide-y dark:divide-[#2c2c2c] divide-[#cecece] rounded-[4px] bg-white dark:bg-darkBlack dark:border-[#2c2c2c] border-[#cecece] border shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'light'">
                    Light Theme
                  </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'dark'">
                    Dark Theme
                  </button>
                  </MenuItem>
                </div>
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'system'">
                    System
                  </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <div class="open-overlay flex items-center h-[40px] cursor-pointer relative z-[1000]" @click="handleNav">
            <div class="hamburger flex rounded-full">
              <div class="hamburger-track closed">
                <span style="color: white"> Close </span>
              </div>
              <div class="hamburger-button closed">
                <div class="lines closed">
                  <span class="bar" />
                  <span class="bar" />
                  <span class="bar" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNavOpen" class="flex items-center">
          <Menu as="div" class="relative inline-block text-left mr-3">
            <div>
              <MenuButton
                class="inline-flex w-full justify-center rounded-md bg-black/20 p-2 text-sm font-medium dark:text-white hover:bg-black/30 border dark:hover:border-gray-700 border-transparent transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <span class="w-5 h-5">
                  <svg v-if="colorMode.value === 'system'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>
                  <svg v-if="colorMode.value === 'light'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                  </svg>
                  <svg v-if="colorMode.value === 'dark'" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                  </svg>
                </span>
              </MenuButton>
            </div>

            <transition enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0">
              <MenuItems
                class="absolute right-0 mt-2 w-36 origin-top-right divide-y dark:divide-[#2c2c2c] divide-[#cecece] rounded-[4px] bg-white dark:bg-darkBlack dark:border-[#2c2c2c] border-[#cecece] border shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'light'">
                    Light Theme
                  </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'dark'">
                    Dark Theme
                  </button>
                  </MenuItem>
                </div>
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                  <button :class="[
                    active ? 'dark:bg-darkBlack bg-white dark:text-white text-black' : 'text-gray-900 dark:text-white',
                    'group flex w-full items-center rounded-[4px] px-2 py-2 text-sm dark:hover:bg-white dark:hover:text-black hover:bg-darkBlack hover:text-white',
                  ]" @click="colorMode.preference = 'system'">
                    System
                  </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <div class="open-overlay closed flex items-center h-[40px] cursor-pointer relative z-[1000]"
            @click="handleNav">
            <div class="hamburger flex rounded-full">
              <div class="hamburger-track">
                <span style="color: black; line-height: 1"> Open </span>
              </div>
              <div class="hamburger-button">
                <div class="lines">
                  <span class="bar" />
                  <span class="bar" />
                  <span class="bar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="overlay-navigation" style="opacity: 0; visibility: hidden">
      <nav class="nav-container" role="navigation" style="background-color: transparent">
        <ul id="navColumnsWrapper">
          <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <a href="https://kinfolk.vc/en/about">
                  <span
                    class="nav-text font-Playfair text-[32px] lg:text-[46px] text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav">About</span>
                </a>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <a href="https://kinfolk.vc/en/about/philosophy">
                  <span class="font-Playfair text-sm font-normal" data-content="Philosophy"
                    @click="handleNav">Philosophy</span>
                </a>
                <a href="https://kinfolk.vc/en/about/investment-strategy">
                  <span class="font-Playfair text-sm font-normal" data-content="Investment Strategy"
                    @click="handleNav">Investment Strategy</span>
                </a>
                <a href="https://kinfolk.vc/en/about/impact-commitments">
                  <span class="font-Playfair text-sm font-normal" data-content="Impact Commitments"
                    @click="handleNav">Impact Commitments</span>
                </a>
                <a href="https://kinfolk.vc/en/privacy-policy">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Privacy Policy</span>
                </a>
                <a href="https://kinfolk.vc/en/terms-of-use">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Terms
                    of Use</span>
                </a>
                <a href="https://kinfolk.vc/en/anti-harassment-policy">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Anti-Harassment & Non-Discrimination</span>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <a href="https://kinfolk.vc/en/team">
                  <span
                    class="nav-text font-Playfair text-[32px] lg:text-[46px] text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav">Team</span>
                </a>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <a href="https://kinfolk.vc/en/team">
                  <span class="font-Playfair text-sm font-normal" data-content="Partners"
                    @click="handleNav">Partners</span>
                </a>
                <a href="#">
                  <span class="font-Playfair text-sm font-normal" data-content="kinfolk-collective"
                    @click="handleNav">Collective
                    <i class="text-xs text white italic font-Rubik">(coming soon)</i></span>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <a href="https://kinfolk.vc/en/companies">
                  <span
                    class="nav-text font-Playfair text-[32px] lg:text-[46px] text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav">Companies</span>
                </a>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <a href="https://kinfolk.vc/en/companies">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Portfolio</span>
                </a>
                <NuxtLink to="/founder-profiles">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Founder Profiles</span>
                </NuxtLink>
              </div>
            </div>
          </li>
          <!-- <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <NuxtLink href="https://kinfolk.vc/en/stories">
                  <span class="nav-text font-Playfair text-[32px] md:text-[46px] text-white font-normal leading-[55px] mb-0 md:mb-[20px]" @click="handleNav">Stories</span>
                </NuxtLink>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <a href="https://kinfolk.vc/en/stories">
                  <span class="nav-text font-Playfair text-sm font-normal" data-content="Family By Design" @click="handleNav">Read more</span>
                </a>
              </div>
            </div>
          </li> -->
          <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <a href="https://kinfolk.vc/en/contact">
                  <span
                    class="nav-text font-Playfair text-[32px] lg:text-[46px] text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav">Connect</span>
                </a>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <a href="https://kinfolk.vc/en/pitch-us">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Pitch
                    Us</span>
                </a>
                <a href="https://kinfolk.vc/en/contact">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Contact</span>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div class="navitem-container flex h-full flex-col items-center justify-center">
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <NuxtLink to="/">
                  <span
                    class="nav-text font-Playfair text-[32px] lg:text-[46px] text-white font-normal leading-[55px] mb-0 lg:mb-[20px]">Chronicles</span>
                </NuxtLink>
              </div>
              <div class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block">
                <NuxtLink to="/recommended-reads">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Recommended Reads</span>
                </NuxtLink>
                <NuxtLink to="/news">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">News</span>
                </NuxtLink>
                <NuxtLink to="/insights">
                  <span class="font-Playfair text-sm font-normal" data-content="Family By Design"
                    @click="handleNav">Insights</span>
                </NuxtLink>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import { useAppStore } from '../stores/appStore'
import gsap from 'gsap'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

// State variables
const isNavOpen = ref(false)
// const scrollY = ref(0)
const showFullNav = ref(false)
const scrollPosition = ref(0)

const appStore = useAppStore()
const newSubscriberStore = useNewSubscribersStore()
const colorMode = useColorMode()

// Computed properties
const isDark = computed(() => appStore.isDark)
const isShowNewSubscriber = computed(() => newSubscriberStore.showNewSubscribers)

const enabled = ref(appStore.isDark)

// const toggleTheme = () => {
//   enabled.value = !enabled.value
// }


// Helper functions for nav animations
const navAnimations = () => {
  const cols = gsap.utils.toArray('#navColumnsWrapper')
  const animateNavItemsIn = () => {
    cols.forEach((col: any) => {
      gsap.fromTo(
        col.querySelectorAll('li'),
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.8, stagger: 0.2 }
      )
    })
  }
  const animateNavItemsOut = () => {
    cols.forEach((col: any) => {
      gsap.fromTo(
        col.querySelectorAll('li'),
        { autoAlpha: 1 },
        { autoAlpha: 0, duration: 0.8, stagger: -0.2 }
      )
    })
  }
  if (isNavOpen.value) {
    gsap.fromTo('.overlay-navigation', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.1 })
    gsap.fromTo('.nav-container', { backgroundColor: 'transparent' }, { backgroundColor: '#000000', duration: 2 })
    animateNavItemsIn()
  } else {
    gsap.fromTo('.overlay-navigation', { autoAlpha: 1 }, { autoAlpha: 0, duration: 1.3 })
    gsap.fromTo('.nav-container', { backgroundColor: '#000000' }, { backgroundColor: 'transparent', duration: 1.5 })
    animateNavItemsOut()
  }
}

// Toggle nav and handle dark mode
const handleNav = () => {
  isNavOpen.value = !isNavOpen.value
  navAnimations()
  // newSubscriberStore.setShowSubscribers(!isNavOpen.value)
}

// Scroll handler to show/hide full navigation based on scroll direction
const handleScroll = () => {
  const currentScrollPosition = window.scrollY
  showFullNav.value = currentScrollPosition < scrollPosition.value
  scrollPosition.value = currentScrollPosition
}

// Set up event listeners
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  const storedValue = localStorage.getItem('is-dark-mode');
  enabled.value = storedValue === 'true';
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

// watch(enabled, (newValue) => {
//   appStore.toggleTheme(enabled.value)
//   localStorage.setItem('is-dark-mode', newValue.toString());
// })
</script>

<style scoped>
.navTransition {
  transition: all 500ms ease 0s;
}
</style>
