<template>
  <div class="footer relative py-[38px] bg-black flex flex-col items-center">
    <div class="flex items-center mb-[32px]">
      <router-link to="/" class="logo mr-[12.4px]">
        <KinfolkLogo :dark="false" />
      </router-link>
      <span
        class="block text-white tracking-[0.25px] leading-[16px] text-[12px] font-[400] font-Playfair"
        >Kinfolk Venture Capital</span
      >
    </div>
    <div class="socials flex items-center mb-[32px]">
      <a href="https://twitter.com/kinfolkvc">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3553 4.74149C14.3655 4.88362 14.3655 5.02577 14.3655 5.1679C14.3655 9.5029 11.066 14.4978 5.03553 14.4978C3.17766 14.4978 1.45178 13.9597 0 13.0258C0.263969 13.0562 0.51775 13.0664 0.791875 13.0664C2.32484 13.0664 3.73603 12.5486 4.86294 11.6654C3.42131 11.6349 2.21319 10.6907 1.79694 9.39124C2 9.42168 2.20303 9.44199 2.41625 9.44199C2.71066 9.44199 3.00509 9.40137 3.27919 9.33034C1.77666 9.02574 0.649719 7.70596 0.649719 6.11205V6.07146C1.08625 6.31512 1.59391 6.4674 2.13194 6.48768C1.24869 5.89884 0.670031 4.89377 0.670031 3.75671C0.670031 3.14759 0.832438 2.58921 1.11672 2.1019C2.73094 4.09174 5.15734 5.39121 7.87813 5.53337C7.82738 5.28971 7.79691 5.03593 7.79691 4.78212C7.79691 2.97499 9.25884 1.50293 11.0761 1.50293C12.0203 1.50293 12.873 1.89887 13.472 2.53846C14.2131 2.39634 14.9238 2.12221 15.5533 1.74659C15.3096 2.50802 14.7918 3.14762 14.1116 3.55368C14.7715 3.48265 15.4111 3.29987 15.9999 3.04609C15.5533 3.6958 14.9949 4.27446 14.3553 4.74149Z"
            fill="white"
          />
        </svg>
      </a>
      <a href="https://instagram.com/kinfolkvc">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.96827 4.02409C5.76 4.02409 3.9788 5.8053 3.9788 8.01357C3.9788 10.2218 5.76 12.003 7.96827 12.003C10.1765 12.003 11.9578 10.2218 11.9578 8.01357C11.9578 5.8053 10.1765 4.02409 7.96827 4.02409ZM7.96827 10.6072C6.54123 10.6072 5.37459 9.44409 5.37459 8.01357C5.37459 6.58305 6.53776 5.41989 7.96827 5.41989C9.39879 5.41989 10.562 6.58305 10.562 8.01357C10.562 9.44409 9.39532 10.6072 7.96827 10.6072ZM13.0515 3.8609C13.0515 4.37825 12.6348 4.79143 12.1209 4.79143C11.6036 4.79143 11.1904 4.37478 11.1904 3.8609C11.1904 3.34703 11.6071 2.93037 12.1209 2.93037C12.6348 2.93037 13.0515 3.34703 13.0515 3.8609ZM15.6938 4.80532C15.6347 3.55883 15.35 2.45469 14.4368 1.54499C13.5272 0.635297 12.423 0.350583 11.1765 0.288084C9.89183 0.21517 6.04124 0.21517 4.75656 0.288084C3.51353 0.347111 2.4094 0.631825 1.49623 1.54152C0.583058 2.45122 0.301816 3.55536 0.239317 4.80185C0.166403 6.08654 0.166403 9.93713 0.239317 11.2218C0.298344 12.4683 0.583058 13.5724 1.49623 14.4821C2.4094 15.3918 3.51006 15.6766 4.75656 15.7391C6.04124 15.812 9.89183 15.812 11.1765 15.7391C12.423 15.68 13.5272 15.3953 14.4368 14.4821C15.3465 13.5724 15.6313 12.4683 15.6938 11.2218C15.7667 9.93713 15.7667 6.09001 15.6938 4.80532ZM14.0341 12.6003C13.7633 13.2808 13.239 13.8051 12.555 14.0794C11.5307 14.4856 9.10019 14.3919 7.96827 14.3919C6.83636 14.3919 4.4024 14.4821 3.38159 14.0794C2.70106 13.8086 2.17676 13.2843 1.90247 12.6003C1.49623 11.576 1.58998 9.14548 1.58998 8.01357C1.58998 6.88166 1.4997 4.44769 1.90247 3.42689C2.17329 2.74635 2.69758 2.22206 3.38159 1.94776C4.40587 1.54152 6.83636 1.63527 7.96827 1.63527C9.10019 1.63527 11.5342 1.54499 12.555 1.94776C13.2355 2.21859 13.7598 2.74288 14.0341 3.42689C14.4403 4.45117 14.3466 6.88166 14.3466 8.01357C14.3466 9.14548 14.4403 11.5794 14.0341 12.6003Z"
            fill="white"
          />
        </svg>
      </a>
      <a href="https://linkedin.com/company/kinfolkvc">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.94548 15.4084H0.840012V5.40787H3.94548V15.4084ZM2.39108 4.04371C1.39805 4.04371 0.59259 3.2212 0.59259 2.22817C0.59259 1.75118 0.782073 1.29373 1.11935 0.956452C1.45664 0.61917 1.91409 0.429687 2.39108 0.429688C2.86806 0.429687 3.32552 0.61917 3.6628 0.956452C4.00008 1.29373 4.18956 1.75118 4.18956 2.22817C4.18956 3.2212 3.38377 4.04371 2.39108 4.04371ZM15.5683 15.4084H12.4695V10.5402C12.4695 9.37998 12.4461 7.89211 10.8549 7.89211C9.2403 7.89211 8.99288 9.15262 8.99288 10.4566V15.4084H5.89075V5.40787H8.86917V6.77203H8.91263C9.32723 5.9863 10.34 5.15711 11.8509 5.15711C14.9938 5.15711 15.5716 7.22675 15.5716 9.91495V15.4084H15.5683Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
    <div class="copyright text-center">
      <a
        href="https://www.google.com/maps/place/20+Burlington+Mall+Road+%23420,+Burlington,+MA+01803,+USA/data=!4m2!3m1!1s0x89e39fd4ee955555:0x439c6c160d4be495?sa=X&ved=1t:242&ictx=111"
        target="_blank"
        class="leading-[20px] text-[13px] font-Rubik text-greyText"
      >
        20 Mall Road Suite 420, Burlington, Massachusetts, USA
      </a>
      <p class="leading-[20px] text-[13px] font-Rubik text-greyText">
        © {{ currentDate }} All Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      currentDate: "",
    };
  },
  mounted() {
    this.currentDate = new Date().getFullYear();
  },
};
</script>

<style scoped>
.socials a:not(:last-child) {
  margin-right: 33px;
}
</style>
