import { defineStore } from 'pinia'

export const useNewSubscribersStore = defineStore({
  id: 'newSubscribersStore',
  state: () => ({ 
    showNewSubscribers: false
  }),
  actions: {
    async setShowSubscribers(data: boolean) {
      this.showNewSubscribers = data
    },
    reset() {
      this.showNewSubscribers = false
    }
  }
})
