<template>
  <svg
    :class="dark ? 'text-black' : 'text-white'"
    width="37"
    height="32"
    viewBox="0 0 37 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3830_8473)">
      <path
        d="M12.4221 0.105513C13.9627 0.169306 15.5017 0.140599 17.0407 0.0831856C17.3852 0.0704271 17.7297 0.0433152 18.0726 0.00982407C18.1954 -0.00293446 18.2624 0.00822926 18.24 0.148573C18.2368 0.166116 18.24 0.183659 18.24 0.201202C18.2384 0.422882 18.3086 0.682837 18.2145 0.855077C18.1156 1.03689 17.819 0.976283 17.6101 1.02253C15.7537 1.42602 14.2976 2.45308 13.143 3.92191C10.6375 7.10676 8.14803 10.3044 5.64576 13.4924C5.52455 13.6455 5.59951 13.7189 5.67766 13.8273C8.31548 17.5193 10.9517 21.2113 13.5879 24.9033C14.4364 26.0915 15.2944 27.2716 16.1269 28.4693C17.0343 29.7771 18.2592 30.6144 19.7918 31.0083C19.9545 31.0497 20.0103 31.1103 19.9975 31.273C19.98 31.5011 20.0645 31.7961 19.9513 31.9381C19.8428 32.0736 19.5398 31.9412 19.3229 31.9317C17.4474 31.8456 15.5719 31.8376 13.6948 31.8519C12.5274 31.8599 11.36 31.887 10.1958 31.9763C9.97409 31.9939 9.86724 31.9604 9.90073 31.7068C9.92784 31.5075 9.84172 31.2491 9.93901 31.1135C10.0411 30.9716 10.317 31.045 10.5147 31.0147C10.872 30.9588 11.2324 30.903 11.5593 30.7372C11.9628 30.533 12.0824 30.246 11.9437 29.8154C11.8639 29.5698 11.738 29.3481 11.596 29.1328C9.29948 25.6704 7.00135 22.2081 4.70322 18.7473C4.3412 18.2019 3.94569 17.682 3.5119 17.1876C2.8022 16.379 1.90591 15.966 0.846957 15.8767C0.626872 15.8591 0.406787 15.832 0.185108 15.8256C0.062307 15.8224 0.0208418 15.7921 0.0128677 15.6614C-0.0301923 14.915 -0.0333819 14.9198 0.690665 14.8608C2.51035 14.7156 4.04137 13.9549 5.18645 12.5467C6.94075 10.3921 8.65996 8.20878 10.3345 5.99039C10.8831 5.26315 11.368 4.49285 11.7188 3.64601C11.8512 3.32704 11.9549 2.99851 11.9708 2.64925C12.0011 1.99378 11.7204 1.52809 11.1208 1.25697C10.612 1.02732 10.0714 0.944387 9.51957 0.934818C9.27237 0.930033 9.17349 0.871025 9.18625 0.591932C9.21336 -0.0284515 9.19423 -0.0140982 9.82099 0.0162033C10.6886 0.062453 11.5561 0.0784012 12.4221 0.105513Z"
        fill="currentColor"
      />
      <path
        d="M28.4234 0.0828325C30.3165 0.0828325 32.2111 0.0892118 34.1041 0.0796429C34.8027 0.0764533 35.5012 0.052531 36.1981 0.00309171C36.4549 -0.0160461 36.49 0.0509362 36.4645 0.28059C36.3114 1.65213 36.2428 3.03005 36.1965 4.40957C36.1423 6.02034 36.179 7.62791 36.3002 9.23389C36.3178 9.45717 36.2667 9.5385 36.0419 9.50342C35.9462 9.48906 35.8473 9.50023 35.75 9.50023C35.2381 9.50023 35.2508 9.49863 35.1934 8.97394C35.0323 7.48757 34.8138 6.01236 34.4119 4.56746C33.8601 2.58032 32.5428 1.49744 30.527 1.20718C29.7631 1.09714 28.9975 1.03972 28.2272 1.04929C26.0312 1.078 23.8367 1.00304 21.6422 0.995068C21.2627 0.993473 20.8799 0.980714 20.5035 1.04132C20.3728 1.06205 20.3201 1.04291 20.3154 0.88662C20.2898 0.135461 20.2835 0.135462 21.0378 0.135462C23.5002 0.135462 25.961 0.135462 28.4234 0.135462C28.4234 0.117919 28.4234 0.100376 28.4234 0.0828325Z"
        fill="currentColor"
      />
      <path
        d="M23.1632 15.8778C23.9686 15.8778 24.7756 15.9049 25.5794 15.8698C26.4182 15.8331 27.2188 15.6258 27.7898 14.948C28.3767 14.2511 28.71 13.4265 28.8647 12.5318C28.9986 11.7488 29.1214 10.9625 29.2458 10.1763C29.2714 10.012 29.3096 9.90359 29.5233 9.92911C29.7849 9.95941 30.1373 9.84777 30.2904 9.98174C30.4483 10.1189 30.3112 10.4777 30.2904 10.7361C30.1182 12.9018 30.2027 15.0692 30.2123 17.2366C30.2203 18.9956 30.3271 20.7531 30.4659 22.5058C30.4834 22.7195 30.4388 22.812 30.2059 22.7833C30.0225 22.761 29.8343 22.7674 29.6493 22.7817C29.4643 22.7977 29.4181 22.7243 29.3942 22.5473C29.2538 21.4963 29.1278 20.4437 28.8711 19.4119C28.7945 19.1025 28.6637 18.8138 28.5202 18.53C27.9397 17.3801 26.9493 16.8937 25.7197 16.8395C24.0579 16.7645 22.3929 16.8187 20.7295 16.8155C20.4967 16.8155 20.4313 16.7629 20.4377 16.5253C20.4552 15.8873 20.4425 15.8873 21.0915 15.8873C21.7821 15.8873 22.4727 15.8873 23.1632 15.8873C23.1632 15.8826 23.1632 15.8794 23.1632 15.8778Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3830_8473">
        <rect width="36.4751" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
